import React from "react";

const AppAlertModal = (props) => {
    return (
        <>
            <div className={props.class ? "app-alert-modal " + props.class : "app-alert-modal"}>
                <div className="modal-wrapper">
                    <div className="modal-header text-bold">
                        {props.messageData.heading}
                    </div>
                    <div className="modal-body">
                        <p dangerouslySetInnerHTML={{__html: props.messageData.message}}></p>
                    </div>
                    <div className="modal-footer">
                        <p className="btn-container btn-container--center">
                            <button className="btn btn-primary" type="button" onClick={props.tryAgain}>
                                {props.messageData.btnText}
                            </button>
                        </p>
                        {props.messageData.linkText && (
                            <button className="app-alert-modal__text-link" onClick={props.goBack}>
                                {props.messageData.linkText}
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </>
    );
};

export default AppAlertModal;
