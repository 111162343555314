import React from "react";
import scannerIcon from "../../assets/images/imei-camera-icon.svg";

const ImeiField = ({ name, label, type, formik, placeholder, info, showImeiUI, imeiLength }) => {
    const startScan = () => {
        showImeiUI(true);
    };

    const isInvalidClass = () => {
        return formik.touched[name] && formik.errors[name] ? "is-invalid" : "";
    };

    return (
        <div className="form-group">
            <label className="form-group__label" htmlFor={name}>
                {label}
            </label>

            <div className="form-group__field-wrapper form-group__field-wrapper--imei">
                <input
                    className={`form-group__form-control form-control ${isInvalidClass()}`}
                    type={type !== null ? type : "text"}
                    maxLength={imeiLength ? imeiLength : null}
                    id={name}
                    {...formik.getFieldProps(name)}
                    placeholder={placeholder}
                />

                <button className="form-group__field-trailing-button" type="button" onClick={startScan}>
                    <img className="form-group__field-trailing-icon img-fluid" src={scannerIcon} alt="imei-scan" />
                </button>

            </div>

            {formik.touched[name] && formik.errors[name] ? (
                <div className="form-group__error invalid-feedback">{formik.errors[name]}</div>
            ) : null}

            {info && <small className="form-group__info form-text">{info}</small>}
        </div>
    );
};

export default ImeiField;
