import React, { useImperativeHandle, forwardRef, useState, useMemo } from "react";
import { useIntl } from "gatsby-plugin-intl";

const WearableErrorAlertModal = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const intl = useIntl();
    const messages = intl.messages;

    const onHandleShow = () => {
        setVisible(true);
    };

    const onHandleHide = () => {
        setVisible(false);
    };

    useImperativeHandle(ref, () => ({
        onHandleShow: onHandleShow,
        onHandleHide: onHandleHide,
    }));

    const onHandleGetModalClassName = useMemo(() => {
        const classNames = ["app-alert-modal", "app-alert-modal--hidden"];

        if (visible) classNames.pop();

        return classNames.join(" ");
    }, [visible]);

    return (
        <>
            <div className={onHandleGetModalClassName}>
                <div className="modal-wrapper">
                    <div className="modal-body">
                        <p>{messages["WearableErrorAlertModal.instruction"]}</p>
                    </div>
                    <div className="modal-footer">
                        <p className="btn-container btn-container--center">
                            <button className="btn btn-primary" type="button" onClick={props.onHandleConfirm}>
                                {messages["WearableErrorAlertModal.cta.back"]}
                            </button>
                        </p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default forwardRef(WearableErrorAlertModal);
