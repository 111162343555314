import React, { Fragment, useCallback, useEffect, useRef } from "react";
import { FormattedMessage, FormattedHTMLMessage } from "gatsby-plugin-intl";
import Quagga from "quagga";

import useIsMount from "../../hooks/use-is-mount";

const AppImeiScanner = (props) => {
    const isMount = useIsMount();
    let timer = useRef(null);
    let onDetect = useRef(null);

    const getCameraId = useCallback(() => {
        const initQuag = (cameraId) => {
            Quagga.init(
                {
                    inputStream: {
                        name: "Live",
                        type: "LiveStream",
                        target: document.querySelector("#barcode-scanner"),
                        area: {
                            top: "40%",
                            right: "10%",
                            left: "10%",
                            bottom: "40%",
                        },
                        constraints: {
                            width: 1920,
                            height: 1080,
                            deviceId: cameraId,
                            focusMode: "continuous",
                        },
                    },
                    locate: false,
                    decoder: {
                        readers: ["code_39_reader", "code_39_vin_reader", "code_128_reader"],
                    },
                },
                (err) => {
                    if (err) {
                        console.log(err);
                        props.isShowImeiScanner(false);
                        props.deniedCameraAlert(true);
                        return;
                    }
                    console.log("Initialization finished. Ready to start");
                    Quagga.start();
                }
            );

            let resultArr = [];

            onDetect.current = Quagga.onDetected((result) => {
                let last_code = result.codeResult.code;
                [...last_code].length > 6 && resultArr.push(last_code);
                if (resultArr.length > 10) {
                    Quagga.stop();
                    props.getImei(getFrequentItem(resultArr));
                    props.isShowImeiScanner(false);
                }
            });
        };

        Quagga.CameraAccess.enumerateVideoDevices().then((deviceIds) => {
            initQuag(deviceIds[deviceIds?.length - 1]?.deviceId);
        });
    }, [props]);

    const startTimer = useCallback(() => {
        timer.current = setTimeout(() => {
            Quagga.offDetected(onDetect.current);
            props.showAlert(true);
        }, 30000);
    }, [props]);

    useEffect(() => {
        if (isMount) {
            getCameraId();
            startTimer();
        }
    }, [isMount, getCameraId, startTimer]);

    useEffect(() => {
        return () => {
            timer.current && clearTimeout(timer.current);
            Quagga.offDetected(onDetect.current);
            Quagga.stop();
        };
    }, []);

    useEffect(() => {
        if (!props.tryAgain && !timer.current) {
            getCameraId();
            startTimer();
        }
    }, [props.tryAgain, getCameraId, startTimer]);

    const getFrequentItem = (resultArr) => {
        if (resultArr.length === 0) return null;
        let modeMap = {};
        let maxEl = resultArr[0],
            maxCount = 1;
        for (let i = 0; i < resultArr.length; i++) {
            let el = resultArr[i];
            if (modeMap[el] === null) modeMap[el] = 1;
            else modeMap[el]++;
            if (modeMap[el] > maxCount) {
                maxEl = el;
                maxCount = modeMap[el];
            }
        }
        return maxEl;
    };

    return (
        <Fragment>
            <div className="app-imei-scanner">
                <div className="title">
                    <FormattedMessage id="imeiScanner.title" />
                </div>

                <div className="barcode-scanner-wrapper" id="barcode-scanner">
                    <div className="scan-area">
                        <div className="overlay overlay-left"></div>
                        <div className="overlay overlay-top"></div>
                        <div className="overlay overlay-bottom"></div>
                        <div className="scan-frame">
                            <span className="top-left"></span>
                            <span className="top-right"></span>
                            <span className="bottom-left"></span>
                            <span className="bottom-right"></span>
                        </div>
                        <div className="overlay overlay-right"></div>
                    </div>
                </div>

                <div className="scanner-footer">
                    <div className="container">
                        <p className="instruction">
                            <FormattedHTMLMessage id="imeiScanner.instruction" />
                        </p>
                        <div className="picture-icon img-fluid"></div>
                        <button className="go-back" type="button" onClick={() => props.isShowImeiScanner(false)}>
                            <FormattedMessage id="imeiScanner.cta.back" />
                        </button>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default AppImeiScanner;
