import React, { useState, useEffect, useCallback, useRef } from "react";
import { navigate, useIntl, FormattedMessage } from "gatsby-plugin-intl";
import moment from "moment";
import { useFormik } from "formik";
import * as Yup from "yup";

import api from "../../service/api";
import * as constants from "../../app.constant";
import useIsMount from "../../hooks/use-is-mount";
import errorTranslationKeyStore from "../../store/errorTranslationKeyStore";
import deviceStore from "../../store/deviceStore";
import planStore from "../../store/planStore";
import AppLayout from "../../components/shared/AppLayout";
import ImeiField from "../../components/form/imei-field";
import DateInputField from "../../components/form/date-field";
import AppAlertModal from "../../components/shared/AppAlertModal";
import AppImeiScanner from "../../components/shared/AppImeiScanner";
import AppStepsIndicator from "../../components/shared/AppStepsIndicator";
import WearableErrorAlertModal from "../../components/pages/imei/WearableErrorAlertModal";
import imeiExample from "../../assets/images/imei_example2_guide.png";

const PageIMEI = () => {
    const isMount = useIsMount();
    const [showImeiScanner, setShowImeiScanner] = useState(false);
    const [showAlert, setShowAlert] = useState(false);
    const wearableErrorAlertModalRef = useRef(null);
    const today = new Date();
    const fourteenDaysAgo = new Date(new Date().setDate(new Date().getDate() - 14));
    const intl = useIntl();
    const messages = intl.messages;
    const alertObj = {
        heading: messages["imei.alertMessageData.title"],
        message: messages["imei.alertMessageData.message"],
        btnText: messages["imei.alertMessageData.cta.tryAgain"],
        linkText: messages["imei.alertMessageData.cta.back"],
    };
    const errors = {
        devicePurchaseDate: messages["imei.form.purchaseDateField.required"],
        deviceImei: messages["imei.form.imeiField.required"],
    }

    const formik = useFormik({
        initialValues: {
            deviceImei: deviceStore.deviceImei || "",
            devicePurchaseDate: deviceStore.devicePurchaseDate || ""
        },
        validationSchema: Yup.object({
            deviceImei: Yup.string().required(errors.deviceImei),
            devicePurchaseDate: Yup.string().test("devicePurchaseDate", errors.devicePurchaseDate, value => (value) ? true : false).required(errors.devicePurchaseDate).nullable()
        }),
        onSubmit: (values, action) => {
            action.setSubmitting(true);
            deviceStore.updateDeviceDetail({
                ...deviceStore,
                deviceImei: values.deviceImei,
                devicePurchaseDate: values.devicePurchaseDate
            });

            onHandleValidateDevice();
        },
    });

    const goToAccountPage = () => {
        navigate(constants.ROUTES.ACCOUNT);
    };

    const goToDeviceInfoUnsuccessfulPage = () => {
        navigate(constants.ROUTES.DEVICE_INFO_UNSUCCESSFUL);
    };

    const showImeiUI = value => {
        setShowImeiScanner(value);
    };

    const deniedCameraAlert = show => {
        setShowAlert(show);
    };

    const showAlertModal = show => {
        setShowAlert(show);
    };

    const goBackFromAlert = () => {
        setShowImeiScanner(false);
        showAlertModal(false);
    };

    const getImei = imei => {
        formik.setFieldValue("deviceImei", imei);
    };

    const hideAlerts = () => {
        setShowAlert(false);
    };

    const onHandleValidateDevice = async () => {
        const dateFormat = "YYYY-MM-DD";
        const timeFormat = "T00:00:00.000+0000";
        try {
            const payload = {
                imei: formik.values.deviceImei,
                purchaseDate: moment(formik.values.devicePurchaseDate, constants.DATE_FORMAT).format(dateFormat) + timeFormat,
                planType: planStore.planType
            };
            const response = await api.post.validateDevice(payload);
            deviceStore.updateDeviceValidate(response.data);
            goToAccountPage();
        }
        catch(error) {
            const errorTranslationKey = error?.response?.data?.translationKey;
            const deviceNotMatchKey = "partnerService.validateDevice.planNotSupportedForDevice";

            if (deviceNotMatchKey === errorTranslationKey) {
                formik.setSubmitting(false);
                return onHandleShowAlertError();
            }

            if (errorTranslationKey) errorTranslationKeyStore.updateTranslationKey(errorTranslationKey);

            goToDeviceInfoUnsuccessfulPage();
        }

        formik.setSubmitting(false);
    }

    const onHandleShowAlertError = useCallback(() => {
        if(wearableErrorAlertModalRef.current.onHandleShow) wearableErrorAlertModalRef.current.onHandleShow();
    }, [wearableErrorAlertModalRef]);
    
    const onHandleHideAlertError = useCallback(() => {
        if(wearableErrorAlertModalRef.current.onHandleHide) wearableErrorAlertModalRef.current.onHandleHide();
    }, [wearableErrorAlertModalRef]);

    useEffect(() => {
        if (isMount) {
            !planStore.planType && navigate(constants.ROUTES.PLANS);
        }
    }, [isMount]);

    return (
        <AppLayout hasHeader={true} hasHeaderLogo={true} isBackBtn={true} hasFooter={true} prevUrl={constants.ROUTES.PLANS} isLoading={formik.isSubmitting}>
            {showImeiScanner ? (
                <AppImeiScanner
                    getImei={getImei}
                    isShowImeiScanner={showImeiUI}
                    deniedCameraAlert={deniedCameraAlert}
                    showAlert={showAlertModal}
                />
            ) : null}

            {showAlert && (
                <AppAlertModal
                    goBack={goBackFromAlert}
                    messageData={alertObj}
                    tryAgain={hideAlerts}
                />
            )}

            <WearableErrorAlertModal ref={wearableErrorAlertModalRef} onHandleConfirm={onHandleHideAlertError} />

            <AppStepsIndicator step="1" />

            <div className="app-page page-imei">

                <div className="imei">

                    <div className="imei__container container">

                        <form className="imei__form" noValidate autoComplete="off" onSubmit={formik.handleSubmit}>

                            <h2 className="imei__title text-bold">
                                <FormattedMessage id="imei.purchaseDate.header" />
                            </h2>

                            <DateInputField
                                label={messages["imei.form.purchaseDateField.label"]}
                                name="devicePurchaseDate"
                                formik={formik}
                                info={messages["imei.purchaseDate.disclaimer"]}
                                maxDate={today}
                                minDate={fourteenDaysAgo}
                            />

                            <small className="imei__note">
                                <FormattedMessage id="imei.purchaseDate.note" />
                            </small>

                            <h2 className="imei__title text-bold">
                                <FormattedMessage id="imei.phoneModel.header" />
                            </h2>

                            <ImeiField
                                showImeiUI={showImeiUI}
                                label={messages["imei.form.imeiField.label"]}
                                placeholder="e.g. 306169445194800"
                                name="deviceImei"
                                formik={formik}
                            />

                            <div className="imei__instructions">
                                <p className="imei__instructions-title text-bold">
                                    <FormattedMessage id="imei.instructions.title" />
                                </p>
                                <ol className="imei__instructions-list">
                                    <li>
                                        <FormattedMessage id="imei.instructions.listItems.0.text" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="imei.instructions.listItems.1.text" />
                                    </li>
                                    <li>
                                        <FormattedMessage id="imei.instructions.listItems.2.text" />
                                        <img className="imei__instructions-img img-fluid" src={imeiExample} alt="imei-sample" />
                                    </li>
                                </ol>
                            </div>

                            <p className="btn-container btn-container--center">
                                <button className="btn btn-lg btn-primary" type="submit" disabled={formik.isSubmitting || !formik.isValid}>
                                    <FormattedMessage id="imei.form.cta" />
                                </button>
                            </p>
                        </form>

                    </div>

                </div>

            </div>

        </AppLayout>
    );
};

export default PageIMEI;
